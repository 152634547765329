
<div class="max1200 mt20 m-auto flexRowB itemsSS layoutContainer">
  <div class="flexGrow0 leftQueryContainer">
    <template v-if="$attrs.filter">
      <div class="flexGrow0 leftQueryContainer">
        <div class="p18 searchCondition">
          <div class="label">精简查询结果</div>
          <div class="flexRowS mt26 uptime">
            <div class="mr10 clockIcon" />
            <span>上架时间</span>
          </div>
          <div class="flexRow inputContainer mt30">
            <div class="inputLabel">自</div>
            <input
              type="text"
              v-model="startTime"
              class="inputText"
              @blur="updateTime"
            />
            <div class="inputLabel">至</div>
            <input
              type="text"
              v-model="endTime"
              class="inputText"
              @blur="updateTime"
            />
          </div>
          <div class="flexRow sliderContainer mt33">
            <van-slider
              :key="timeRangeStart + '_' + timeRangeEnd"
              v-model="value"
              :max="timeRangeEnd"
              :min="timeRangeStart"
              range
              active-color="#088190"
              inactive-color="#e5e7eb"
              bar-height="4"
              @input="onChange"
              @change="changeRange"
            >
              <template #left-button>
                <div class="sliderActive">
                  <div class="endPoint startItem">
                    <div class="year">{{ value[0] }}</div>
                  </div>
                </div>
              </template>
              <template #right-button>
                <div class="sliderActive">
                  <div class="endPoint endItem">
                    <div class="year">{{ value[1] }}</div>
                  </div>
                </div>
              </template>
            </van-slider>
          </div>
        </div>
      </div>
    </template>
    <template v-if="$attrs.checkboxconfig">
      <!-- 基础筛选条件 -->
      <div
        class="mt0 p20 px18 pb10 bgw"
        :class="[$attrs.checkboxconfig.classList]"
      >
        <div class="pt20 searchLabel" v-if="!$attrs.checkboxconfig.hidelabel">
          精简查询结果
        </div>
        <div class="flexRowS pt20 uptime">
          <div
            class="mr10 clockIcon"
            :class="[$attrs.checkboxconfig.icon || '']"
          />
          <span class="typeLabel">{{ $attrs.checkboxconfig.title }}</span>
        </div>
        <div class="inputContainer mt30">
          <div
            v-for="item in $attrs.checkboxconfig.list"
            :key="item.id"
            class="cursor flexRowS specialTypeItem mb20"
            @click="
              searchType = 'by hand';
              checkedKey = item.id;
            "
          >
            <div
              :class="[
                item.id === checkedKey
                  ? 'checkboxCheckedIcon'
                  : 'checkboxIcon',
              ]"
              class="mr10"
            />
            <span>
              {{ item.name }}
              <template v-if="$attrs.countmap"
                >({{ $attrs.countmap[item.countProp] || 0 }})</template
              >
            </span>
          </div>
        </div>
      </div>
    </template>
    <slot name="leftQueryContainer" :type="checkedKey" />
  </div>
  <div class="flexGrow1 queryBody pl11">
    <div class="rightContainer">
      <div class="flexRowS pl19 topBreakNav mb10">
        <template v-if="!$slots.bradcard">
          首页
          <span class="mx10">></span>
          <span class="last">{{ $attrs.title || "电子书" }}</span>
        </template>
        <slot name="bradcard" :type="checkedKey"></slot>
      </div>
      <slot name="topBlock" />
      <div class="baseDataView p20">
        <slot name="topcondition"></slot>
        <template v-if="$attrs.order">
          <div class="flexRowB orderCondition">
            <div class="flexRow">
              <template v-if="!$attrs.hideorder">
                <div
                  class="cursor upStoreTime"
                  @click="
                    timeOrder = !timeOrder;
                    orderBy = 0;
                  "
                >
                  上架时间
                  <div :class="[timeOrder ? 'orderDown' : 'orderUp']" />
                </div>
                <div
                  class="cursor ml30 upStoreTime"
                  @click="
                    hotOrder = !hotOrder;
                    orderBy = 1;
                  "
                >
                  热度
                  <div :class="[hotOrder ? 'orderDown' : 'orderUp']" />
                </div>
              </template>
              <template v-if="$attrs.countmap">
                <div class="flexRowS bookTypeListContainer ml20">
                  <div
                    v-for="bookType in $attrs.checkboxconfig.list"
                    :key="bookType.id"
                    class="flexRow cursor bookTypeItem mr25"
                    :class="[
                      bookType.id === checkedKey ? 'bookTypeItemActive' : '',
                    ]"
                    @click="
                      searchType = 'by hand';
                      checkedKey = bookType.id;
                    "
                  >
                    {{ bookType.name }} ({{
                      ($attrs.countmap &&
                        $attrs.countmap[bookType.countProp]) ||
                      0
                    }})
                  </div>
                </div>
              </template>
            </div>

            <div class="flexRow displayType">
              <div
                class="cursor displayBlock"
                :class="[
                  displayType === 'block'
                    ? 'displayBlockActive'
                    : 'displayBlock',
                ]"
                @click="changeDisplayType('block')"
              />
              <div
                class="cursor ml17 displayList"
                :class="[
                  displayType === 'list'
                    ? 'displayListActive'
                    : 'displayList',
                ]"
                @click="changeDisplayType('list')"
              />
            </div>
          </div>
        </template>
        <slot name="dataView" :display="displayType" />
        <slot name="bottomBlock" />
      </div>
    </div>
  </div>
</div>
