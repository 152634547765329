const state = {
  userName: '',
  searchList: [
    {
      id: 1,
      name: 'python',
    },
    {
      id: 2,
      name: '战争',
    },
    {
      id: 3,
      name: '乌克兰',
    },
    {
      id: 4,
      name: 'githut',
    },
    {
      id: 5,
      name: '股票',
    },
    {
      id: 6,
      name: '一带一路',
    },
  ],
  hotList: [
    {
      id: 1,
      name: 'python',
    },
    {
      id: 2,
      name: '战争',
    },
    {
      id: 3,
      name: '乌克兰',
    },
    {
      id: 4,
      name: 'githut',
    },
    {
      id: 5,
      name: '股票',
    },
    {
      id: 6,
      name: '一带一路',
    },
  ],
  selectLevel1List: [], // 一级分类列表
  selectLevel1Info: {}, // 选中的一级分类的信息
};
const mutations = {
  SET_USER_NAME(state, name) {
    state.userName = name;
  },
};
const actions = {
  // 设置name
  setUserName({ commit }, name) {
    commit('SET_USER_NAME', name);
  },
};
export default {
  state,
  mutations,
  actions,
};
