
<section class="text-gray-600 body-font">
  <template v-if="true">
    <div class="flexRow" style="height:80vh;">登录处理中,请稍后!</div>
  </template>
  <template v-else>
    <div class="container flex flex-col items-center justify-center px-5 py-24 mx-auto">
      <img
        class="object-cover object-center w-5/6 mb-10 rounded lg:w-2/6 md:w-3/6"
        alt="hero"
        src="@/assets/not-found.svg"
      />
      <div class="w-full text-center lg:w-2/3">
        <p
          class="mb-8 leading-relaxed dark:text-gray-300"
        >Oops, looks like you got kidnapped by aliens.</p>
        <div class="flex justify-center">
          <RouterLink :to="{ name: 'Home' }">
            <div>Bring me back Home</div>
          </RouterLink>
        </div>
      </div>
    </div>
  </template>
</section>
