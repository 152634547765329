import api from './indexApi';
// axios
import request from '@/utils/request';

// 登录
export function login(data) {
  return request({
    url: api.login,
    method: 'get',
    params: data,
  });
}

// 登出
export function logout() {
  return request({
    url: api.logout,
    method: 'get',
  });
}
// SSO登出
export function ssoLogout() {
  return request({
    url: api.ssoLogout,
    method: 'get',
  });
}

// 用户信息 post 方法
export function getUserInfo(data) {
  return request({
    url: api.getUserInfo,
    method: 'get',
    data,
    hideloading: true,
  });
}

// 用户名称 get 方法
export function getUserName(params) {
  return request({
    url: api.UserName,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 更新用户信息
export function updateUserInfo(data) {
  console.error('data', data);
  const updateInfo = {
    nickName: data.nickname || '',
    avatar: data.photo || '',
    realName: data.username || '',
    sex: data.gender === '男' ? 0 : 1,
    birthyDay:
      new Date(data.birthdate).toLocaleString().replaceAll('/', '-') || '',
    // provinceCode: data.province || '',
    // cityCode: data.city || '',
    // countyCode: data.country || '',
    // streetCode: data.streetAddress || '',
    phonenumber: data.phone,
  };
  return request({
    url: api.updateUserInfo,
    method: 'get',
    params: updateInfo,
    hideloading: true,
  });
}
