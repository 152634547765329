<template>
  <div id="app" class="fullWidth m-auto">
    <transition name="fade">
      <RouterView />
    </transition>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { defineComponent } from "@vue/composition-api";
import { Button, Toast } from "vant";
import { getBookCountByType } from "@/api/bookDetail";
import { getUserInfo } from "@/api/user";
import { getAppConfig } from "@/api/index";

Vue.use(Toast);
Vue.use(Button);

import Qs from "qs";

import SideBar from "@/components/SideBar.vue";

export default defineComponent({
  computed: {
    ...mapState(["userInfo", "pathInfo"]),
  },
  components: {
    SideBar,
  },
  async created() {
    await this.getAppConfig();
    this.$watch(
      "$route",
      () => {
        let query = Qs.parse(location.search.substring(1));
        if (!location.search) {
          let queryString = "";
          queryString = location.hash.replace("#/index?", "");

          query = Qs.parse(queryString); /* 登录回调地址 */
        }
        let id_token = query.token;
        if (id_token) {
          this.$store.dispatch("LOGIN", { token: id_token }).then(
            (s) => {
              window.location.href =
                this.pathInfo.url ||
                `${location.origin}${location.pathname}#/index`;
              this.$store.dispatch("savePathInfo", { url: "" });
            },
            (e) => {
              // window.location.href = `${location.origin}${location.pathname}#/home`
            }
          );
        }
      },
      { immediate: true }
    );

    const { code, data, msg } = await getBookCountByType({ ObjectType: 54 });
    if (code !== 200) {
      this.$toast(msg);
    }
    this.$store.commit("CHANGE_BOOK_NUM", data !== 0);
  },
  methods: {
    async getAppConfig() {
      const { code, msg, data: configMap = {} } = await getAppConfig();
      if (code !== 200) {
        this.$toast(msg);
        return;
      }
      if (configMap["logo"]) {
        window.__appBaseConfig__.WEB_APP_LOGO_PATH = configMap["logo"];
      }
      if (configMap["poster"]) {
        window.__appBaseConfig__.WEB_APP_SHARE_LOGO_PATH = configMap["poster"];
      }
      if (configMap["icon"]) {
        $("link[rel=icon]")[0].href = configMap["icon"];
      }

      if (configMap["business"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_COPER =
          configMap["business"];
      }
      if (configMap["email"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_EMAIL = configMap["email"];
      }
      if (configMap["tel"]) {
        window.__appBaseConfig__.systemInfo.SERVICE_TEL = configMap["tel"];
      }
      if (configMap["version"]) {
        window.__appBaseConfig__.systemInfo.SYSTEM_VERSION =
          configMap["version"];
      }
      this.$store.commit("UPDATE_APP_CONFIG", window.__appBaseConfig__);
    },
  },
});
</script>

<style lang="less">
.fullWidth {
  max-width: 1920px;
}
#app {
  overflow: auto;
}
</style>
