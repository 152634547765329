<!-- 商品详情 -->
<template>
  <div class="poasterPopBg">
    <div
      class="flexRow commentContainerPop postImageContainer"
      :style="{
        background: `${$attrs.book.BookPosterUrl ? 'transparent' : '#fff'}`,
      }"
    >
      <div class="cursor closeIconWhite" @click="$emit('closeshare')"></div>
      <div
        id="catImg"
        class="innerContainer"
        style="padding: 0px; background: #fff"
      >
        <template v-if="$attrs.book.BookPosterUrl">
          <div class="imgContainer" style="padding: 0px; background: #fff">
            <!-- <img
              :src="require(`@/assets/test/book-h.png`)"
              style="width: 100%; height: 100%; border: 0px solid #f00"
              alt
            /> -->

            <img
              :src="`${$attrs.book.BookPosterUrl}?v=${Math.ceil(
                Math.random() * 100000
              )}`"
              crossorigin="anonymous"
              :key="`${$attrs.book.BookPosterUrl}`"
              style="width: 100%; height: 100%; border: 0px solid #f00"
              alt
            />
          </div>
        </template>
        <template v-else>
          <div class="imgContainer">
            <img
              :src="`${
                $attrs.book.BookPosterUrl ||
                $attrs.book.subImg ||
                $attrs.book.CoverUrl
              }?v=${Math.ceil(Math.random() * 100000)}`"
              crossorigin="anonymous"
              :key="`${
                $attrs.book.BookPosterUrl ||
                $attrs.book.subImg ||
                $attrs.book.CoverUrl
              }`"
              style="width: 100%; height: 100%; border: 0px solid #f00"
              alt
            />
          </div>
          <div class="dashLine"></div>
          <div class="shareBottomContainer">
            <img :src="shareBottomBg" class="shareBottomContainerImg" alt />
            <div class="qrCode" v-if="$attrs.shareUrl">
              <VueQr :size="110" margin="0" :text="$attrs.shareUrl"></VueQr>
            </div>
          </div>
        </template>
      </div>
      <div class="cursor flexRow bottomCloseContainer" @click="download">
        <div class="xiazaiIcon"></div>
        <div class="downloadText">下载保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueQr from "vue-qr";
import { Toast } from "vant";
import html2canvas from "html2canvas";

import defaultLogo from "@/assets/base/shareBottomBg@2x.png";
import testLogo from "@/assets/images/logo/test/shareLogo.png";
import yanjiuyuanLogo from "@/assets/images/logo/yanjiuyuan/shareLogo.png";

const logoConfig = {
  default: defaultLogo,
  test: testLogo,
  yanjiuyuan: yanjiuyuanLogo,
};

Vue.use(Toast);

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      shareBottomBg:
        window.__appBaseConfig__.WEB_APP_SHARE_LOGO_PATH ||
        logoConfig[window.__appBaseConfig__.WEB_APP_SHARE_LOGO_PATH] ||
        logoConfig["default"],
    };
  },
  created() {},
  methods: {
    download() {
      const opts = {
        scale: 3,
        useCORS: true, // 【重要】开启跨域配置
        allowTaint: true,
        logging: true,
        backgroundColor: null,
      };
      const copyEle =
        document.getElementById("catImg") || document.createElement("div");

      html2canvas(copyEle, opts).then((canvas) => {
        const url = canvas.toDataURL();
        // 以下代码为下载此图片功能
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${this.$attrs.book.Title}.png`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
      this.$emit("closeshare");
    },
  },
};
</script>

<style lang="less" scoped>
.commentContainerPop {
  zoom: 0.75;
  width: 590px;
  background: #fff;
  border-radius: 10px;
  padding: 63px 0 53px 0px;
  align-items: flex-start;
  position: relative;
  .titleContainer {
    margin-bottom: 41px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
  .tipMessage {
    width: 475px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 51px;
  }
  .commentStarContainer {
    width: 522px;
    border: 0px solid #e5e5e5;
    padding: 20px;
    position: relative;
  }
  .commentInputContainer {
    width: 522px;
    height: 301px;
    border: 1px solid #e5e5e5;
    padding: 20px;
    position: relative;
    textarea {
      height: 100%;
      width: 100%;
      resize: none;
      border: none;
    }
    .inputLength {
      position: absolute;
      bottom: 20px;
      right: 20px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .buttonContainer {
    padding-top: 40px;
    .continueBtn {
      width: 207px;
      height: 73px;
      background: #dedede !important;
      border-radius: 36px;
    }
    .saveBtn {
      width: 181px;
      height: 73px;
      background: #088190;
      border-radius: 36px;

      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .bottomCloseContainer {
    position: absolute;
    bottom: -60px;
    .downloadText {
      margin-left: 20px;
      font-size: 25px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .xiazaiIcon {
      width: 35px;
      height: 35px;
    }
  }
}
.sharePopContainer {
  width: 749px;
  background: #ffffff;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 60px;
  .cancelShare {
    height: 104px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .bottomLine {
    margin: 59px 0 0 0;
    border-bottom: 1px solid #e7e7e7;
  }
  .sharesItem {
    text-align: center;
    .shareIconImg {
      width: 91px;
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
    .shareName {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
.postImageContainer {
  padding: 0 !important;
  flex-grow: 0;
  flex-shrink: 0;
  background: rgb(255, 255, 255);
  padding: 0px;
  width: 547px;
  .innerContainer {
    width: 100%;
    padding: 30px;
    border-radius: 5px;
    overflow: hidden;
    .imgContainer {
      width: 100%;
      height: 676px !important;
      border-radius: 5px !important;
      position: relative;
      padding: 14px;
    }
    .dashLine {
      width: 100%;
      border-bottom: 1px dashed #eee;
      margin: 20px 0;
    }
    .shareBottomContainer {
      position: relative;
      margin-top: 0px;
      .qrCode {
        width: 146px;
        height: 146px;
        top: 26px;
        left: 22px;
        position: absolute;
      }
    }
  }

  .closeIconWhite {
    position: absolute;
    top: -50px;
    right: -50px;
    width: 40px;
    height: 40px;
  }
}
</style>
