
<div class="flexRow InnerPriceContainer" v-if="$attrs.book">
  <div class="flexRowS" v-if="$attrs.book && $attrs.book.tDiscount">
    <template v-if="$attrs.book.tDiscount.discountType == '0'">
      <span
        class="bookPrice mr10"
        :class="{ bookPriceFade: $attrs.colorreset }"
      >¥ {{ Number(accMul(($attrs.book.price || $attrs.book.BookPrice), $attrs.book.tDiscount.discountRatio)).toFixed(2) }}</span>
      <span class="ml0 flexRow youhui">限时优惠{{ accMul($attrs.book.tDiscount.discountRatio, 10) }}折</span>
    </template>
    <template v-if="$attrs.book.tDiscount.discountType == '1'">
      <template v-if="$attrs.book.tDiscount.spikePrice * 1 === 0">
        <div class="flexRowS">
          <slot name="lineprice"></slot>
          <span class="freeToDate">限时免费至{{ $attrs.book.tDiscount.endDate.substr(0, 10) }}</span>
        </div>
      </template>
      <template v-else>
        <span
          class="bookPrice"
          :class="{ bookPriceFade: $attrs.colorreset }"
        >¥ {{ $attrs.book.tDiscount.spikePrice }}</span>
        <span class="ml10 flexRow killPrice">秒杀</span>
      </template>
    </template>
  </div>
  <div
    class="bookPrice"
    style="text-decoration: none;"
    v-else
    :class="{ bookPriceFade: $attrs.colorreset }"
  >¥ {{ $attrs.book.BookPrice || $attrs.book.price }}</div>
</div>
