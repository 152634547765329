import Vue from 'vue'
import { createApp, h } from 'vue-demi'
import VueCompositionApi from '@vue/composition-api'
import {isPC} from '@/utils/index'
const { WEB_BASE_URL } = window.__appBaseConfig__;

import App from '@/App.vue'

// filters
import './filters';

import 'windi.css'
import router from '@/router'

import "./app.less"

import BaseQuery from '@/components/QueryLayout.vue'
import BaseListPager from '@/components/BaseListPager.vue'
import BookDiscount from '@/components/BookDiscount.vue'
import BookDiscountTag from '@/components/BookDiscountTag.vue'


// import Vant from 'vant';
import 'vant/lib/index.css';

import '@vant/touch-emulator';
// Vue.use(Vant);
import Poster from '@/views/detail/components/Poster.vue';

Vue.component('book-poster', Poster);
Vue.component('base-query', BaseQuery)
Vue.component('list-pager', BaseListPager)
Vue.component('book-discount', BookDiscount)
Vue.component('book-discount-tag', BookDiscountTag)

import { store } from '@/store/index'

Vue.use(VueCompositionApi)

Vue.config.productionTip = false
Vue.config.devtools = true

document.title=(window.__appBaseConfig__||{title:'数字内容运营平台'}).title

if(!isPC()){
  const baseUrl = `${WEB_BASE_URL}h5/#/home`;
  window.location.href=baseUrl
}


const app = createApp({
  router,
  store,
  render: () => h(App),
})

app.mount('#app')
