/* 图书类型 图书类型：53-图书，54-有声书，55-视频，64-条目文档对应字典book_type */
export const BookType = {
  ebook: 53,
  sbook: 54,
  course: 55,
  other: 64,
  special: 1,
  none: '',
};

/* 书类型对应详情跳转地址 */
export const H5BookTypeDetail = {
  53: 'ebookDetail',
  54: 'soundBookDetail',
  55: 'courseBookDetail',
  64: 'other',
  0: 'special',
};

/* 专题类型对应跳转地址 */
export const SpecialTypeDetail = {
  53: '/ebook',
  54: '/soundBook',
  55: '/course',
  64: '/other',
  0: '/special',
};

/* 书类型对应详情跳转地址 */
export const BookTypeDetail = {
  53: '/detail',
  54: '/soundBook',
  55: '/courseBook',
  64: '/other',
  0: '/special',
};

export const BookTypeList = [
  {
    id: 53,
    name: '电子书',
    detailUrl: '/',
  },
  {
    id: 54,
    name: '有声书',
    detailUrl: '/',
  },
  {
    id: 55,
    name: '视频',
    detailUrl: '/',
  },
  {
    id: 1,
    name: '专题',
    detailUrl: '/',
  },
];

export const bookTypeNameMap = {
  53: '电子书',
  54: '有声书',
  55: '视频',
  64: '未知',
  ebook: 53,
  sbook: 54,
  course: 55,
  other: 64,
  special: 0,
};


/* 开发环境 使用用户id登录 在测试环境登录后查看用户id */
export const devUser = {"code":200,"msg":"操作成功","data":{"token":"eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjljMGQyZjM0LTg2ZTktNDY3YS04ODdlLWNhYTA4OGUyY2YyYiJ9.8IMDACBEAXntPC69p5-QHOleYb9Bws1aApUkWq6dMU5jvsVtJ-esxpnhx7mToXsgcBS1-SZrzl_9Zytk13EHWA","userId":594,"userInfo":{"createTime":"2022-09-08 21:46:54","updateBy":"13521309583","updateTime":"2022-09-16 08:47:34","params":{},"userId":594,"userName":"13521309583","nickName":"13521309583","userType":"01","email":"","phonenumber":"13521309583","sex":"0","status":"0","delFlag":"0","loginIp":"101.72.41.59:12716","loginDate":"2022-09-16 08:47:34","oosUserId":"327377114021957","useLength":25052,"dcdStatus":"0","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoie1wiVXNlcklkXCI6MzI3Mzc3MTE0MDIxOTU3LFwiSXNBZG1pblwiOmZhbHNlLFwiQ2xpZW50SXBcIjpcIjEwMS43Mi40MS41OVwifSIsIm5iZiI6MTY2MzI4OTI1MiwiZXhwIjoxNjYzMjk2NDUyLCJpYXQiOjE2NjMyODkyNTJ9.yQ2iPh7JEh20Ks6Fci4BmcPiwvnYbBbW9zRL16gz9DI","deptIdList":[],"admin":false}}}
export const devToken= 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoie1wiVXNlcklkXCI6MzI3Mzc3MTE0MDIxOTU3LFwiSXNBZG1pblwiOmZhbHNlLFwiQ2xpZW50SXBcIjpcIjEwMS43Mi40MS41OVwifSIsIm5iZiI6MTY2MzI4OTI1MiwiZXhwIjoxNjYzMjk2NDUyLCJpYXQiOjE2NjMyODkyNTJ9.yQ2iPh7JEh20Ks6Fci4BmcPiwvnYbBbW9zRL16gz9DI'
export default 1;
