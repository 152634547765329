/* 分类选择本地存储 */
import { useStorage } from '@vueuse/core';
const localCategoryList = useStorage('categoryInfo', {
  activeIdLevel1: '',
  activeIdLevel3: '',
  level1: {},
  level2: {},
  leve3: {},
  level1List: [],
  level2List: [],
  level3List: [],
});
const state = {
  categoryInfo: localCategoryList.value,
};
const mutations = {
  UPDATE_CLICK_INDEX(state, payload) {
    localCategoryList.value = { ...localCategoryList.value, ...payload };
    state.categoryInfo = localCategoryList.value;
  },
  UPDATE_ITEM(state, payload) {
    localCategoryList.value = { ...localCategoryList.value, ...payload };
    state.categoryInfo = localCategoryList.value;
  },
};
const actions = {
  updateHitIndex({ commit }, payload) {
    commit('UPDATE_CLICK_INDEX', payload);
  },
  updateItem({ commit }, payload) {
    commit('UPDATE_ITEM', payload);
  },
};
export default {
  state,
  mutations,
  actions,
};
