// axios
import request from '@/utils/request';

const api = {
  login: '/dev-api/webApi/login',
  updateUserInfo: '/dev-api/webApi/userInfo/updateUserInfo',
  getUserInfo: '/dev-api/webApi/userInfo/getUserInfo',
  logout: '/dev-api/webApi/logout',
  ssoLogout: 'https://hep-center.u2.hep.com.cn/logout' /* sso退出登录 */,
  specialList: '/dev-api/webApi/special/specialList',
  specailSearch: '/dev-api/webApi/special/tSpecialSubjectSearchList',
  adList: '/dev-api/webApi/ad/adList',
  userLike: '/dev-api/webApi/special/userLike',
  defaultSearchText: '/dev-api/webApi/platformConfi/getPlatformSearchKey',
  fpglOpen: '/dev-api/webApi/invoice/fpglOpen',
  appConfig: 'dev-api/business/websiteConfig/getConfig',
  adClick:`dev-api/business/adInfo/click`
};

// 登录
export function specialList(data) {
  return request({
    url: api.specialList,
    method: 'get',
    data,
  });
}

export function baseLoad(config) {
  return request(config);
}

export function download(url, fileName) {
  const fileExt = url.split('.').reverse()[0];
  var a = document.createElement('a');
  a.download = `${fileName}.${fileExt}`;
  a.href = url;
  document.getElementsByTagName('body')[0].append(a);
  a.click();
  a.remove();
}

// 登录
export function specialSearch(data) {
  return request({
    url: api.specialSearch,
    method: 'get',
    data,
  });
}

export function userLike(params) {
  return request({
    url: api.userLike,
    method: 'get',
    params,
  });
}

// 广告列表
export function adList(params) {
  return request({
    url: api.adList,
    method: 'get',
    params,
  });
}

// 用户信息 post 方法
export function getUserInfo(data) {
  return request({
    url: api.UserInfo,
    method: 'post',
    data,
    hideloading: true,
  });
}

// 用户名称 get 方法
export function getUserName(params) {
  return request({
    url: api.UserName,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 默认搜索词
export function defaultSearchText(params) {
  return request({
    url: api.defaultSearchText,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 是否可申请开票
export function fpglOpen(params) {
  return request({
    url: api.fpglOpen,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 应用配置
export function getAppConfig(params) {
  return request({
    url: api.appConfig,
    method: 'get',
    params,
    hideloading: true,
  });
}

// 广告点击
export function adClick(id) {
  return request({
    url: `${api.adClick}?tAdId=${id}`,
    method: 'get',
    hideloading: true,
  });
}