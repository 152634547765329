/* 侧边工具箱 */
<template>
  <div class="sideBarContainer" v-if="!hideSideBar">
    <div
      v-for="item in navList"
      :key="item.id"
      class="flexRow"
      :class="[
        'listItem cursor',
        currentActive.indexOf(item.activeKey) !== -1 ? 'active' : '',
      ]"
      @click="menuClick(item)"
    >
      <div class="flexColumn">
        <div class="flexRow mb9">
          <div :class="['normalIcon', { [item.icon]: true }]"></div>
          <div :class="['activeIcon', { [item.icon + 'Active']: true }]"></div>
        </div>
        <div class="name">{{ item.name }}</div>
        <template v-if="item.id === 2">
          <div class="aboutInfoContainer">
            <div class="contextContainer">
              <img class="hideTest" :src="logoUrl" alt />
              <div class="textContainer">
                <div>客服电话：{{ serviceInfo.SERVICE_TEL }}</div>
                <div>客服邮箱：{{ serviceInfo.SERVICE_EMAIL }}</div>
                <div>商务合作：{{ serviceInfo.SERVICE_COPER }}</div>
                <div>当前版本：{{ serviceInfo.SYSTEM_VERSION }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import defaultLogo from "@/assets/base/shareBottomBg@2x.png";
import testLogo from "@/assets/images/logo/test/logo.png";
import yanjiuyuanLogo from "@/assets/images/logo/yanjiuyuan/logo.png";
import { mapState } from "vuex";

const logoConfig = {
  default: defaultLogo,
  test: testLogo,
  yanjiuyuan: yanjiuyuanLogo,
};

export default {
  computed: {
    ...mapState(["appConfig"]),
    serviceInfo() {
      console.log("this.appConfig.serviceInfo", this.appConfig);
      return this.appConfig.systemInfo || {};
    },
    logoUrl() {
      return this.appConfig.WEB_APP_LOGO_PATH;
    },
  },
  data() {
    return {
      searchText: "",
      currentActive: "/",
      hideSideBar: false,
      top: 0,
      navList: [
        {
          id: 1,
          name: "个人中心",
          icon: "user",
          path: "/",
          activeKey: "/my/index",
          listPath: "/my/index",
        },
        {
          id: 2,
          icon: "dianHua",
          name: "联系我们",
          activeKey: "~~",
          listPath: "",
        },
        {
          id: 3,
          icon: "toTop",
          name: "回到顶部",
          activeKey: "/topTop",
        },
      ],
    };
  },
  created() {
    this.$watch(
      "$route",
      (newValue) => {
        this.currentActive = newValue.path;
        this.hideSideBar = newValue.meta.hideSideBar;
      },
      { immediate: true }
    );
  },
  mounted() {
    this.handleScroll();
    $(window).on("resize", (e) => {
      this.handleScroll();
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        const scroolTop = $(document).scrollTop();
        this.top = $(window).height() / 2 - 450 / 2 + scroolTop + "px";
        const right = ($(window).width() - 1200) / 2 - 90;
        $(".sideBarContainer").animate(
          {
            top: $(window).height() / 2 + "px",
            right: (right > 0 ? right : 10) + "px",
            position: "fixed",
          },
          10
        );
        clearTimeout(this.timerId);
      }, 10);
    },
    menuClick(item) {
      if (item.id === 3) {
        document.documentElement.scrollTop = 0;
        return;
      }
      this.goPage(item.listPath, item.id);
    },
    goPage(path, id) {
      console.log(path, id);
      if (!path) {
        console.log("功能开发中...");
        return;
      }
      // if (id === this.currentActiveId) {
      //   return
      // }
      this.currentActiveId = id;
      this.$router.push(`${path}`);
    },
  },
};
</script>
<style lang="less" scoped>
@media (max-width: 1200px) {
  //<=1200的设备
  .sideBarContainer {
    // right: 10px !important;
  }
}
@keyframes showMenu {
  0% {
    opacity: 0;
    border-radius: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    border-radius: 4px;
    z-index: 999;
    width: 270px;
  }
}
@keyframes showMenuReverse {
  0% {
    opacity: 1;
    border-radius: 4px;
    z-index: 999;
    width: 270px;
  }
  100% {
    opacity: 0;
    border-radius: 0;
    width: 0;
  }
}

.sideBarContainer {
  position: fixed;
  top: 260px;
  right: 0px;
  z-index: 19990;
  display: inline-block;
  height: auto;
  .user {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/user@2x.png") center center no-repeat;
    background-size: contain;
  }
  .userActive {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/userActive@2x.png") center center
      no-repeat;
    background-size: contain;
  }

  .dianHua {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/dianhua@2x.png") center center no-repeat;
    background-size: contain;
  }
  .dianHuaActive {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/dianhuaActive@2x.png") center center
      no-repeat;
    background-size: contain;
  }

  .toTop {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/toTop@2x.png") center center no-repeat;
    background-size: contain;
  }
  .toTopActive {
    width: 21px;
    height: 22px;
    background: url("@/assets/sideBar/toTopActive@2x.png") center center
      no-repeat;
    background-size: contain;
  }

  .bookshelfactive {
    width: 15px;
    height: 15px;
    background: url("@/assets/index/bookshelfactive@2x.png") center center
      no-repeat;
    background-size: contain;
  }

  .listItem {
    width: 72px;
    height: 73px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(190, 190, 190, 0.29);
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    &:hover {
      .aboutInfoContainer {
        display: block;
        width: 540 * 0.66px !important;
        height: 504 * 0.66px !important;
        animation: showMenu 0.5s ease-in 1 forwards;
      }
    }
    .aboutInfoContainer {
      animation: showMenuReverse 0.5s ease-in 1 forwards;
      background: url("@/assets/about/bg@2x.png") center center no-repeat;
      background-size: contain;
      overflow: hidden;
      display: none;
      width: 0;
      height: 252px;
      position: absolute;
      right: 70px;
      top: -80px;
      padding: 18px;
      .contextContainer {
        height: 100%;
        padding: 30px;
        padding-left: 20px;
        img {
          width: auto;
          height: 40px;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .textContainer {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          div {
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
    .name {
      width: 48px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #a1a9b0;
    }
    .activeIcon {
      display: none;
    }
    &:hover {
      background: #088190;
      .normalIcon {
        display: none;
      }
      .activeIcon {
        display: inline-block;
      }
      .name {
        color: #ffffff;
      }
    }
  }
  .active {
    background: #088190;
    .normalIcon {
      display: none;
    }
    .activeIcon {
      display: inline-block !important;
    }
    .name {
      color: #ffffff !important;
    }
  }
}
</style>
