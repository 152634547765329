import Vue from 'vue';

const { WEB_BASE_URL,WEB_APPID,WEB_SSOLOGIN_URL} = window.__appBaseConfig__||{};

const Config = {
  appId:WEB_APPID,
  callBackWebUrl: `${window.location.origin}${window.location.pathname}#/index`,
};

const loginUrl = `${WEB_SSOLOGIN_URL}?appId=${Config.appId}&callBackApi=${encodeURIComponent(Config.callBackWebUrl)}`

export function ssoLogin() {
  console.error(loginUrl)
  window.location.replace(loginUrl)
}

export function ssoEditor() {
  console.error(Config.ssoEditor)
  window.location.replace(Config.ssoEditor)
}

 

window.testLogin=()=>{
  console.error(loginUrl)
  window.location.replace(loginUrl)
}

export default {};