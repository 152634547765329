
<div class="sideBarContainer" v-if="!hideSideBar">
  <div
    v-for="item in navList"
    :key="item.id"
    class="flexRow"
    :class="[
      'listItem cursor',
      currentActive.indexOf(item.activeKey) !== -1 ? 'active' : '',
    ]"
    @click="menuClick(item)"
  >
    <div class="flexColumn">
      <div class="flexRow mb9">
        <div :class="['normalIcon', { [item.icon]: true }]"></div>
        <div :class="['activeIcon', { [item.icon + 'Active']: true }]"></div>
      </div>
      <div class="name">{{ item.name }}</div>
      <template v-if="item.id === 2">
        <div class="aboutInfoContainer">
          <div class="contextContainer">
            <img class="hideTest" :src="logoUrl" alt />
            <div class="textContainer">
              <div>客服电话：{{ serviceInfo.SERVICE_TEL }}</div>
              <div>客服邮箱：{{ serviceInfo.SERVICE_EMAIL }}</div>
              <div>商务合作：{{ serviceInfo.SERVICE_COPER }}</div>
              <div>当前版本：{{ serviceInfo.SYSTEM_VERSION }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</div>
