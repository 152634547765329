import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { useStorage } from '@vueuse/core';
import { Toast } from 'vant';

import { login, logout } from '@/api/user';
import getters from './getters';
import app from './modules/app';
import search from './modules/search';
import category from './modules/category';

import {ssoLogin} from '@/utils/login';

Vue.use(Vuex);
const localUserInfo = useStorage('userInfo', { token: '' });
const hashUserInfo = useStorage('hashUserInfo', { token: '' })
const localPathInfo = useStorage('pathInfo', {
  url: '',
}); /* sso登录回调地址 因为sso不支持参数且只能回调到根路径导致之前信息丢失 所有只能缓存本地 回调之后再读取 */

/* 如果resType=0有值,内容类型：53-图书，54-有声书，55-视频，64-条目文档对应字典book_type */
const BookType = {
  ebook: 53,
  sbook: 54,
  course: 55,
  unknow: 64,
  special: 1,
};

// 创建一个新的 store 实例
const store = new Store({
  state() {
    return {
      userInfo: localUserInfo.value,
      BookType,
      pathInfo: localPathInfo.value,
      currentBookName: '',
      catch: {},
      hasSoundBook:true,
      appConfig:{}
    };
  },
  modules: {
    app,
    search,
    category,
  },
  getters,
  mutations: {
    CURRENT_BOOK_NAME(state, payload) {
      state.currentBookName = payload;
    },
    CHANGE_BOOK_NUM(state, payload) {
      state.hasSoundBook = payload;
    },
    SAVE_USER_INFO(state, payload) {
      localUserInfo.value = payload;
      state.userInfo = payload;
    },
    UPDATE_USER_INFO(state, payload) {
      const currentInfo={...state.userInfo,...payload}
      localUserInfo.value = currentInfo;
      state.userInfo =currentInfo ;
    },
    USER_LOGOUT(state) {
      localUserInfo.value = '';
      state.userInfo = {};
    },
    SAVE_PATH_INFO(state, payload) {
      localPathInfo.value = payload;
      state.pathInfo = payload;
    },
    UPDATE_APP_CONFIG(state, payload) {
      state.appConfig = payload;
    },

  },
  actions: {
    USER_LOGOUT(content) {
      content.commit('USER_LOGOUT');
    },
    async LOGIN({ commit, dispatch }, payload) {
       /* 跳转至登录页面 */
      if (payload.token || hashUserInfo.value.token) {
        await dispatch('USER_RELOGIN', { token: payload.token || hashUserInfo.value.token })
        return { msg: 'ok' }
      } else {
        ssoLogin()
      }
      return {msg:'ok'};
    },

    async USER_RELOGIN({ commit, dispatch }, payload) {
      if (!payload) return;
      const {token} = payload;
      try {
        const { code, msg, data } = await login({idToken:token,appId:window.__appBaseConfig__.WEB_APPID});
        if (code === 200) {
          commit('SAVE_USER_INFO', {accessToken:token, ...data,...data.userInfo, username: data.userInfo.nickName, userName: data.userInfo.nickName });
          return { msg: 'ok' };
        } else {
          Toast(msg);
        }
      } catch (e) {
        console.error('获取用户token信息异常', e);
      }
    },

    async logout({ commit }, payload) {
      const { code, msg } = await logout();
      if (code === 200) {
        commit('USER_LOGOUT');
        return { msg: 'ok' };
      } else {
        Toast(msg);
        return { msg };
      }
    },
    async savePathInfo({ commit }, payload) {
      console.log(payload);
      commit('SAVE_PATH_INFO', payload);
      return { msg: 'ok' };
    },
  },
});
export default 1;
export { store };
