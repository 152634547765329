/* 搜索 默认布局 包含 面包屑 左侧查询 右侧展示  filter 左侧精简查询 checkboxconfig 配置资源类型*/
<template>
  <div class="max1200 mt20 m-auto flexRowB itemsSS layoutContainer">
    <div class="flexGrow0 leftQueryContainer">
      <template v-if="$attrs.filter">
        <div class="flexGrow0 leftQueryContainer">
          <div class="p18 searchCondition">
            <div class="label">精简查询结果</div>
            <div class="flexRowS mt26 uptime">
              <div class="mr10 clockIcon" />
              <span>上架时间</span>
            </div>
            <div class="flexRow inputContainer mt30">
              <div class="inputLabel">自</div>
              <input
                type="text"
                v-model="startTime"
                class="inputText"
                @blur="updateTime"
              />
              <div class="inputLabel">至</div>
              <input
                type="text"
                v-model="endTime"
                class="inputText"
                @blur="updateTime"
              />
            </div>
            <div class="flexRow sliderContainer mt33">
              <van-slider
                :key="timeRangeStart + '_' + timeRangeEnd"
                v-model="value"
                :max="timeRangeEnd"
                :min="timeRangeStart"
                range
                active-color="#088190"
                inactive-color="#e5e7eb"
                bar-height="4"
                @input="onChange"
                @change="changeRange"
              >
                <template #left-button>
                  <div class="sliderActive">
                    <div class="endPoint startItem">
                      <div class="year">{{ value[0] }}</div>
                    </div>
                  </div>
                </template>
                <template #right-button>
                  <div class="sliderActive">
                    <div class="endPoint endItem">
                      <div class="year">{{ value[1] }}</div>
                    </div>
                  </div>
                </template>
              </van-slider>
            </div>
          </div>
        </div>
      </template>
      <template v-if="$attrs.checkboxconfig">
        <!-- 基础筛选条件 -->
        <div
          class="mt0 p20 px18 pb10 bgw"
          :class="[$attrs.checkboxconfig.classList]"
        >
          <div class="pt20 searchLabel" v-if="!$attrs.checkboxconfig.hidelabel">
            精简查询结果
          </div>
          <div class="flexRowS pt20 uptime">
            <div
              class="mr10 clockIcon"
              :class="[$attrs.checkboxconfig.icon || '']"
            />
            <span class="typeLabel">{{ $attrs.checkboxconfig.title }}</span>
          </div>
          <div class="inputContainer mt30">
            <div
              v-for="item in $attrs.checkboxconfig.list"
              :key="item.id"
              class="cursor flexRowS specialTypeItem mb20"
              @click="
                searchType = 'by hand';
                checkedKey = item.id;
              "
            >
              <div
                :class="[
                  item.id === checkedKey
                    ? 'checkboxCheckedIcon'
                    : 'checkboxIcon',
                ]"
                class="mr10"
              />
              <span>
                {{ item.name }}
                <template v-if="$attrs.countmap"
                  >({{ $attrs.countmap[item.countProp] || 0 }})</template
                >
              </span>
            </div>
          </div>
        </div>
      </template>
      <slot name="leftQueryContainer" :type="checkedKey" />
    </div>
    <div class="flexGrow1 queryBody pl11">
      <div class="rightContainer">
        <div class="flexRowS pl19 topBreakNav mb10">
          <template v-if="!$slots.bradcard">
            首页
            <span class="mx10">></span>
            <span class="last">{{ $attrs.title || "电子书" }}</span>
          </template>
          <slot name="bradcard" :type="checkedKey"></slot>
        </div>
        <slot name="topBlock" />
        <div class="baseDataView p20">
          <slot name="topcondition"></slot>
          <template v-if="$attrs.order">
            <div class="flexRowB orderCondition">
              <div class="flexRow">
                <template v-if="!$attrs.hideorder">
                  <div
                    class="cursor upStoreTime"
                    @click="
                      timeOrder = !timeOrder;
                      orderBy = 0;
                    "
                  >
                    上架时间
                    <div :class="[timeOrder ? 'orderDown' : 'orderUp']" />
                  </div>
                  <div
                    class="cursor ml30 upStoreTime"
                    @click="
                      hotOrder = !hotOrder;
                      orderBy = 1;
                    "
                  >
                    热度
                    <div :class="[hotOrder ? 'orderDown' : 'orderUp']" />
                  </div>
                </template>
                <template v-if="$attrs.countmap">
                  <div class="flexRowS bookTypeListContainer ml20">
                    <div
                      v-for="bookType in $attrs.checkboxconfig.list"
                      :key="bookType.id"
                      class="flexRow cursor bookTypeItem mr25"
                      :class="[
                        bookType.id === checkedKey ? 'bookTypeItemActive' : '',
                      ]"
                      @click="
                        searchType = 'by hand';
                        checkedKey = bookType.id;
                      "
                    >
                      {{ bookType.name }} ({{
                        ($attrs.countmap &&
                          $attrs.countmap[bookType.countProp]) ||
                        0
                      }})
                    </div>
                  </div>
                </template>
              </div>

              <div class="flexRow displayType">
                <div
                  class="cursor displayBlock"
                  :class="[
                    displayType === 'block'
                      ? 'displayBlockActive'
                      : 'displayBlock',
                  ]"
                  @click="changeDisplayType('block')"
                />
                <div
                  class="cursor ml17 displayList"
                  :class="[
                    displayType === 'list'
                      ? 'displayListActive'
                      : 'displayList',
                  ]"
                  @click="changeDisplayType('list')"
                />
              </div>
            </div>
          </template>
          <slot name="dataView" :display="displayType" />
          <slot name="bottomBlock" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { defineComponent } from "@vue/composition-api";

import { Slider, Toast } from "vant";
import { yearRange } from "@/api/bookDetail";

Vue.use(Slider);
Vue.use(Toast);

export default defineComponent({
  components: {},
  setup() {},
  data() {
    let checkbox = "";
    if (this.$attrs.checkboxconfig) {
      const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(
        (i) => i.checked
      );
      if (defaultCheckedList.length > 0) {
        checkbox = defaultCheckedList[0].id;
      }
    }

    return {
      searchType: "",
      orderBy: 0,
      checkedKey: checkbox,
      startTime: "",
      endTime: "",
      timeRangeStart: "",
      timeRangeEnd: "",
      value: [this.$attrs.startyear, this.$attrs.endyear],
      displayType: "block",
      timeOrder: false,
      hotOrder: false,
      research: false,
    };
  },
  computed: {
    orderStatus() {
      let checkbox = "";
      if (this.$attrs.checkboxconfig && !this.$attrs.checkboxconfig.yearRange) {
        checkbox =
          this.$attrs.checkboxconfig.type === "checkbox"
            ? JSON.stringify(this.$attrs.checkboxconfig.list || [])
            : this.checkedKey;
      }
      // console.error('checkbox', checkbox)
      return (
        this.timeOrder +
        "_" +
        this.hotOrder +
        "_" +
        this.research +
        "_" +
        checkbox +
        "_" +
        JSON.stringify(this.value)
      ); //+ '_' + this.startTime + '_' + this.endTime //+ '_' + this.value[0] + "_" + this.value[1]
    },
  },
  created() {
    this.startTime = this.timeRangeStart;
    this.endTime = this.timeRangeEnd;

    // this.$watch("$attrs.checkboxconfig", () => {
    //   if (this.$attrs.checkboxconfig) {
    //     const defaultCheckedList = (this.$attrs.checkboxconfig.list || []).filter(i => i.checked)
    //     console.error('defaultCheckedList', defaultCheckedList)
    //     if (defaultCheckedList.length > 0) {
    //       this.checkedKey = defaultCheckedList[0].id
    //     }
    //   }
    // }, { immediate: true })
    this.$watch(
      "$route.query",
      () => {
        this.searchType = "";
      },
      { immediate: true }
    );

    this.$watch(
      "$attrs.defaultkey",
      () => {
        if (this.$attrs.defaultkey) {
          this.checkedKey = this.$attrs.defaultkey;
        }
      },
      { immediate: true }
    );

    this.$watch(
      "checkedKey",
      async (newValue) => {
        if (
          this.$attrs.checkboxconfig &&
          this.$attrs.checkboxconfig.yearRange
        ) {
          const [start, end] = await this.getYearRange(newValue);
          this.timeRangeStart = start;
          this.startTime = start;
          this.value[0] = start;

          this.timeRangeEnd = end;
          this.endTime = end;
          this.value[1] = end;
          this.research = !this.research;
        }
      },
      { immediate: true }
    );

    this.$watch(
      "$attrs.ObjectType",
      async (newValue) => {
        if (this.$attrs.ObjectType !== undefined) {
          const [start, end] = await this.getYearRange(newValue);
          this.timeRangeStart = start;
          this.startTime = start;
          this.value[0] = start;

          this.timeRangeEnd = end;
          this.endTime = end;
          this.value[1] = end;
          this.research = !this.research;
        }
      },
      { immediate: true }
    );

    this.$watch("$attrs.startyear", (start) => {
      this.timeRangeStart = start;
      this.startTime = start;
      this.value[0] = start;
    });
    this.$watch("$attrs.endyear", (end) => {
      this.timeRangeEnd = end;
      this.endTime = end;
      this.value[1] = end;
    });

    this.$watch(
      "orderStatus",
      (newValue, oldValue) => {
        // console.error('newValue', newValue)
        let checkbox = "";
        if (this.$attrs.checkboxconfig) {
          checkbox =
            this.$attrs.checkboxconfig.type === "checkbox"
              ? (this.$attrs.checkboxconfig.list || [])
                  .filter((i) => i.checked)
                  .map((i) => i.id)
                  .join(",")
              : this.checkedKey;
        }
        const timeOrder = this.timeOrder;
        const hotOrder = this.hotOrder;
        let sortInfo = {};
        if (this.orderBy === 0) {
          // sortInfo.OnShelfDate = this.orderBy === 0 ? 'true' : 'false';
          sortInfo.OnShelfDateisAsc = timeOrder;
        } else {
          // sortInfo.clickThroughRate = this.orderBy === 1 ? 'true' : 'false';
          sortInfo.clickThroughRateisAsc = hotOrder;
        }
        const otherInfo = {};
        if (this.$attrs.ObjectType !== undefined) {
          otherInfo["ObjectType"] = this.$attrs.ObjectType;
        }
        this.$emit("orderchange", {
          ...sortInfo,
          ...otherInfo,
          startYear: this.value[0],
          endYear: this.value[1],
          checkedIds: checkbox,
        });
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      "searchType",
      (newValue, oldValue) => {
        this.$emit("searchtypechange", newValue);
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    async getYearRange(bookType) {
      const { code, msg, data } = await yearRange({ ObjectType: bookType });
      if (code !== 200) {
        Toast(msg);
        return ["", ""];
      }
      return [data.endYear, data.startYear];
    },
    updateTime() {
      const startTime = this.startTime;
      const endTime = this.endTime;
      if (startTime * 1 < this.timeRangeStart) {
        this.startTime = this.timeRangeStart;
      }
      if (startTime * 1 > this.value[1]) {
        this.startTime = this.value[1];
      }
      if (endTime * 1 > this.timeRangeEnd) {
        this.endTime = this.timeRangeEnd;
      }
      if (endTime * 1 < this.value[0]) {
        this.endTime = this.value[0];
      }
      this.value = [this.startTime, this.endTime];
    },
    changeRange() {
      this.startTime = this.value[0];
      this.endTime = this.value[1];
      this.research = !this.research;
    },
    changeDisplayType(type: string) {
      this.displayType = type;
    },
    onChange(value) {
      // console.log('当前值：' + value);
    },
    updateCheckedKey(id) {
      this.checkedKey = id;
    },
  },
});
</script>
<style lang="less" scoped>
.bookTypeListContainer {
  border: 1 solid #f0a;
  margin-top: 29px;
  margin-bottom: 30px;
  .bookTypeItem {
    width: 90px;
    height: 34px;
    color: #999;
    background: #fff;
    border: 1px dashed #999;
    border-radius: 19px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;

    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999;
  }

  .bookTypeItemActive {
    background: #27a3b2;
    box-shadow: 1px 2px 6px 0px rgba(121, 164, 169, 0.74);
    border: 1px solid #ffffff00;
    color: #ffffff;
  }
}
.layoutContainer {
  width: 1200px;
  height: 100%;
  overflow-x: hidden;
  border: 0px solid #f00;
}
.searchLabel {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #222222;
}
.typeLabel {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #222222;
}
.leftQueryContainer {
  width: 257px;
  min-height: 234px;
  box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
  .inputContainer {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
  }
  .checkboxIcon,
  .checkboxCheckedIcon {
    width: 14px;
    height: 14px;
  }

  .leftQueryContainer {
    .label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
    width: 257px;
    .searchCondition {
      width: 257px;
      min-height: 234px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
    }
    .uptime {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #222222;
    }

    .inputContainer {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      .inputText {
        width: 52px;
        height: 23px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        margin: 0 14px;
      }
    }
    .sliderContainer {
      margin-left: auto;
      margin-right: auto;
      width: 180px;
      height: 26px;
      position: relative;
      .sliderBg,
      .sliderActive {
        background: #f0f;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .year {
          position: absolute;
          top: -20px;
          text-align: center;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #088190;
        }
        .startItem {
          transform: translateX(-13px) translateY(-13px);
          position: relative;
        }
        .endItem {
          transform: translateX(-13px) translateY(-13px);
          position: relative;
          .year {
            position: absolute;
            bottom: -20px !important;
            top: auto;
          }
        }

        .bgLine {
          height: 4px;
          background: #eeeeee;
          border-radius: 2px;
        }
        .bgLineActive {
          width: 91px;
          height: 4px;
          background: #088190;
          border-radius: 2px;
        }
      }
    }

    .hotPush {
      width: 257px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
      .seqContainer {
        display: inline-block;
        width: 14px;
        height: 15px;
        border: 0px solid #f0a;
        text-align: center;

        line-height: 15px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #acacac;
      }
      .hotItem {
        &:nth-child(2) {
          .seqContainer {
            background: url("@/assets/base/top1@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
        &:nth-child(3) {
          .seqContainer {
            background: url("@/assets/base/top2@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
        &:nth-child(4) {
          .seqContainer {
            background: url("@/assets/base/top3@2x.png") center center no-repeat;
            background-size: contain;
            color: #fff;
          }
        }
      }
    }
  }
}
.queryBody {
  max-width: 100%;
  overflow: auto;
  .rightContainer {
    min-height: 100%;

    .topBreakNav {
      height: 49px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);

      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #999999;

      .last {
        color: #088190;
      }
    }
    .baseDataView {
      min-height: 100%;
      background: #fff;
      box-shadow: 0px 0px 21px 0px rgba(234, 234, 234, 0.35);
      padding: 20px;
      .orderCondition {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #666666;
      }
      .bookList {
        .listBookItem {
          width: 196px;
          border: 0px solid #f0a;
          overflow: hidden;
          &:nth-child(4n) {
            margin-right: 0 !important;
          }
        }
        .listBookItemRow {
          border: 0px solid #00f;
          .rowTextDetail {
            height: 91px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #666666;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
